import React, { useLayoutEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import SideBar from "./SideBar";

const SplittingPeelingBrittleNails: React.FC = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <div className="section section-lg bg-primary container-top">
        <div className="container ">
          <div className="text-center ">
            <h1>Splitting / Peeling / Brittle Nails</h1>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-9">
              <div className="mb-5">
                <h5>FEW COMMON NAIL CONDITIONS</h5>
                <p>
                  “DID YOU KNOW THAT NAILS CAN REVEAL CLUES TO YOUR OVERALL
                  HEALTH?”
                </p>
                <h5>RIDGING OF THE NAIL</h5>
                <p>LONGITUDINAL RIDGING CAUSES</p>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Prominent with increasing age
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Rheumatoid arthritis
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Lichen planus
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Injury to the nail.
                  </p>
                </p>
                <p>Transverse ridging causes:</p>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Longstanding or severe eczema around the nail fold
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Trauma
                  </p>
                </p>
                <h5>
                  THICKENING OF THE NAIL: SEEN FREQUENTLY IN THE TOENAILS OF THE
                  ELDERLY.
                </h5>
                <p>CAUSES</p>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Long term use of ill-fitting footwear / Neglect of the
                    nails.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Psoriasis
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Fungal infection
                  </p>
                </p>
                <h5>IN GROWING TOENAIL:</h5>
                <h5>
                  OFTEN PRESENTS WITH PAIN, BUT MAY PROGRESS TO INFECTION
                  RESULTS IN DIFFICULTY WHILE WALKING.
                </h5>
                <p></p>CAUSES
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Improperly trimmed nails
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Hyperhidrosis
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Poorly fitting shoes.
                  </p>
                </p>
                <h5> WHITE NAIL</h5>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Congenital
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Minor trauma
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Hypoalbuminaemia in chronic liver disease
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Renal failure
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Fungal infection.
                  </p>
                </p>
                <h5>NAIL BECOMES DETACHED FROM ITS BED AT BASE AND SIDE</h5>
                <p>CAUSES</p>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Psoriasis
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Idiopathic or inherited
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Systemic disease, e.g. thyrotoxicosis
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Skin disease e.g. psoriasis
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Local causes e.g. trauma or chemicals.
                  </p>
                </p>
                <h5>DEPRESSIONS ACROSS THE FINGERNAIL SEEN IN:</h5>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Illness
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Injury to the nail
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Malnourished.
                  </p>
                </p>
                <h5> BRITTLE NAILS:</h5>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Aging.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Certain diseases and conditions.
                  </p>
                </p>
                <h5>ALSO CALLED AS SPOON-SHAPED NAILS:</h5>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Nail has raised ridges and is thin and curved inward.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Associated with iron deficiency Anaemia.
                  </p>
                </p>
                <h5>PITTING OF NAIL PLATE:</h5>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Small Depressions on the Nail Surface
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Eczema
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Psoriasis
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Alopecia Areata
                  </p>
                </p>
                <h5>DIAGNOSIS</h5>
                <p>
                  Depending on duration, various causes, symptoms, appearance,
                  any co-existing medical illness or skin disease, medications
                  history, occupational details, diagnosis is made and if any
                  underlying condition is present it is addressed immediately.
                </p>
                <h5>TREATMENT AT SKIN AND YOU</h5>
                <p>
                  Based on causative factors it can be combination of oral and
                  topical treatment – such as topical and oral antifungals,
                  topical and oral antibiotic, nail supplements, or steroid
                  based injection to control the progression of disease such as
                  in cases of psoriasis.
                </p>
                <h5>WHY SKIN & YOU CLINIC FOR NAILS INFECTIONS?</h5>
                <p>
                  We, at Skin and you, assess, examine and treat all
                  dermatological conditions with utmost care.
                </p>
              </div>
            </div>

            <div className="col-lg-3">
              <SideBar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SplittingPeelingBrittleNails;
