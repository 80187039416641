import React, { useLayoutEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import SideBar from "./SideBar";

const CausesFacialWrinkles: React.FC = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <div className="section section-lg bg-primary container-top">
        <div className="container ">
          <div className="text-center ">
            <h1>Causes of Facial Wrinkles</h1>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-9">
              <div className="mb-5">
                <p>
                  Sun exposure (UV light) – Chronic UV exposure, especially in
                  cities like Mumbai, is one of the most common causes of skin
                  wrinkling. UV radiation leads to the thinning of the epidermis
                  (top layer of the skin) and the breakdown of collagen (the
                  major structural protein in skin), both resulting in premature
                  aging. Excessive radiation can also cause skin cancers and
                  pigmentation. A good sunscreen thus protects the skin from its
                  harmful effects.
                </p>

                <p>
                  Expression lines – These wrinkles are a result of the
                  underlying muscle movement used for facial expression. While
                  making expressions, the skin compresses, leading to wrinkle
                  formation. Initially, these lines appear only when the muscles
                  contract, i.e. when you smile/frown, but with age, these
                  become more permanent and appear even when the muscles are at
                  rest. Botox helps relax these lines.
                </p>

                <p>
                  Facial volume loss – When younger, our face is more plump and
                  fuller, but as time passes, this volume is lost causing laxity
                  and thinning of skin. Fat around the eyes, cheeks, and
                  forehead gets depleted, leading to lax skin and wrinkles.
                  Facial volume loss also results in an older, more gaunt
                  appearance. Non-surgical facial reshaping with dermal fillers
                  can help to replace this lost volume, improving both wrinkles
                  and the overall youthfulness of the face.
                </p>

                <p>
                  Gravity – The pull of gravity draws the skin downwards on the
                  face. This results in drooping cheeks, nasolabial lines (the
                  lines from the corners of the mouth to the nose) and jowls,
                  drooping of the eyebrows and wrinkles.
                </p>

                <p>
                  Sleep lines – Contact with a pillow when sleeping leads to
                  skin compression. One can detect sleep lines from vertically
                  or diagonally-oriented lines on the side of the forehead or
                  cheek.
                </p>

                <p>
                  Glare – Squinting to avert the glare from sunlight often
                  causes frown lines and crow's feet (lines around the eyes on
                  smiling).
                </p>

                <p>
                  Facial fat changes – Facial fat gets redistributed with age;
                  it gets lost in some areas and accumulated in others. This can
                  be seen in the cheeks, around the eyes and temples, producing
                  a gaunt and drawn look. The lower lids may have protruding fat
                  pads. Slack muscles and excessive fat accumulation below the
                  chin produces the familiar double chin.
                </p>

                <p>
                  Lifestyle changes – Due to its inherent sucking movement,
                  smoking is one of the most obvious causes of wrinkling around
                  the mouth. Also, to protect the eyes from the smoke, there can
                  be wrinkling of the area around the eyes causing crow's feet.
                </p>

                <p>
                  Dietary deficiencies – Lack of a balanced diet and a low
                  intake of antioxidants also contribute to taking away the
                  fountain of youth from the face.
                </p>

                <h5>TREATMENTS</h5>
                <p>
                  Sun Exposure – Firstly, wrinkles can be prevented by using a
                  good sunscreen. (For more information click on Sun and Skin).
                  This protects against UV radiation, reducing further damage to
                  the skin.
                </p>

                <p>
                  Expression lines – These are generally treated with Botox,
                  which relaxes the muscles that cause skin compression. This is
                  most commonly performed in the muscles between the brows and
                  around the eyes. Botox injections generally last for 3-4
                  months. By stopping the movement that causes wrinkles, Botox
                  injections also prevent static lines from becoming deeper. If
                  static lines or lines at rest are already present, then
                  anti-wrinkle injections may not do much. However, a regular
                  Botox injection gives the skin a rest from compression, and
                  static wrinkles may improve slightly over time.
                </p>

                <p>
                  Facial volume loss – A simple analogy for facial volume loss
                  is that of a grape – When we are young, our faces look fuller
                  like a grape but as age adds on, the face loses volume and
                  forms wrinkles like a raisin. Replacing this volume
                  effectively by adding dermal fillers reduces wrinkles and
                  revolumises the face. Dermal fillers add volume to the areas
                  of the face that have been affected due to expression
                  formation, gravity, sleep or simply aging. Their effect lasts
                  between 4 and 24 months. Also, by strategically adding volume
                  to certain areas of the face such as the cheeks, undereye
                  hollows (tear troughs), lips, and brows, it is possible to
                  rejuvenate and reshape the face non-surgically.
                </p>

                <p>
                  Wrinkles that are etched into the skin and skin textural
                  irregularities can be treated with RF pixel. While the use of
                  RF pixel is effective, it often requires a period of downtime
                  or recovery. Other procedures that can be done are
                </p>

                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Radiofrequency Skin Tightening
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Deep Chemical Peels
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Dermaroller
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Dermal Fillers Treatment
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Mesotherapy Treatment
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Alma Photo Facial
                  </p>
                </p>

                <h5>WHY SKIN & YOU CLINIC FOR ANTI-WRINKLE TREATMENTS?</h5>
                <p>
                  The doctors at Skin & You Clinic, Mumbai, specialize in the
                  area of non-surgical rejuvenation of the skin. We listen to
                  your concerns and help you decide which treatments suit you
                  best by considering factors like your desired goal, required
                  downtime for recovery and tolerance to the treatment. We also
                  state realistic and achievable outcomes of the treatment.
                </p>
              </div>
            </div>

            <div className="col-lg-3">
              <SideBar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CausesFacialWrinkles;
