import React, { useLayoutEffect } from "react";
import BlogImg1 from "../../../assets/massage-2-390x344.jpg";
import UserImg from "../../../assets/user.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faComment } from "@fortawesome/free-regular-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import SideBar from "./SideBar";

const CosmelanDepigmentationTreatment: React.FC = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <div className="section section-lg bg-primary container-top">
        <div className="container ">
          <div className="text-center ">
            <h1>Cosmelan Depigmentation Treatment</h1>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-9">
              <div className="mb-5">
                <h5>WHAT DOES COSMELAN DEPIGMENTATION TREAT?</h5>
                <p>
                  Skin ageing caused by sun exposure, facial blemishes due to
                  excessive pigmentation and, in general, imperfections caused
                  by melanin, are more and more common problems today… “Dark
                  blemishes”, especially on hands and face, are one of the
                  greatest skin concerns of modern men and women… It will only
                  be possible to remove the skin pigmentation and recover the
                  smoothness of the skin, bringing back its luminosity, with a
                  treatment that has a direct effect on the intimate formation
                  process of the pigment (melanin).
                </p>
                <h5>WHAT IS COSMELAN?</h5>
                <p>
                  Cosmelan treatment guarantees the improvement of the skin
                  blemishes with a very high safety margin. Cosmelan
                  Depigmentation treatment’s principal action is the elimination
                  of melanin patches on the face – Melasma .Also called cholasma
                  if the pigmentation occurs with pregnancy Cosmelan
                  Depigmentation treatment involves a very versatile, dynamic
                  process which is easily adapted to the needs of each
                  individual patient.
                </p>
                <h5>HOW DOES COSMELAN DEPIGMENTATION WORK?</h5>
                <p>
                  Cosmelan Depigmentation treatment action mechanism is based on
                  the inhibition of tyrosinase, a basic enzyme in the melanin
                  (pigment) formation process. Several of the substances present
                  in the Cosmelan formula act by blocking this enzyme, or even
                  by changing the action of the enzyme.
                </p>
                <h5>WHAT ARE THE ADVANTAGES OF COSMELAN DEPIGMENTATION?</h5>
                <p>
                  The Cosmelan Depigmentation treatment offers a series of major
                  advantages and benefits compared with other types of
                  Depigmentation products found in the market:
                </p>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Very effective in all types of Melasma especially deep
                    seated severe Melasma or post inflammatory hyper
                    pigmentation (PIH)
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Effective for pigmentation that has failed to resolve after
                    traditional skin lightening topical products
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Effective for pigmentation that has failed to resolve after
                    traditional treatments such as superficial peels
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Rapid and easy depigmenting action
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Light peeling effect
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Treatment can be used all year round
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Beneficial for acne patients and the PIH after acne has
                    resolved
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Does not contain Hydroquinone or TCA
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    High safety margin
                  </p>
                </p>
                <h5>
                  WHAT RESULTS CAN BE EXPECTED FROM COSMELAN DEPIGMENTATION?
                </h5>
                <p>
                  In 99% of the cases, the appearance of the blemishes improves
                  by 95%.
                </p>
                <p>
                  ONE WEEK after starting the Cosmelan Depigmentation treatment,
                  a noticeable significant improvement of the skin is observed.
                  The reduction of the treated blemish is detected and the
                  appearance of new luminous and rejuvenated skin is present.
                </p>
                <p>
                  At the end of the Cosmelan Depigmentation treatment, the
                  blemishes start to disappear altogether, so that, although the
                  patient may be able to “locate” the place where the spot was,
                  the truth is that the pigmentation will have disappeared and
                  nobody will be able to see it just by looking at it.
                </p>
                <h5>
                  WHAT CONDITIONS CAN BE TREATED WITH COSMELAN DEPIGMENTATION
                  TREATMENT?
                </h5>
                <p>
                  Cosmelan works by blocking the process that causes hyper
                  pigmentation.
                </p>
                <p>It works for</p>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Melasma
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Brown Spots
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Blotchy Skin
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Hyper pigmentation
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Post-inflammatory hyper pigmentation after any damage to the
                    skin like acne
                  </p>
                </p>
                <h5></h5>WHAT PRECAUTIONS MUST BE UNDERTAKEN BEFORE PROCEEDING
                WITH COSMELAN?
                <p>
                  Stop all applications especially glycolic acid two weeks
                  before treatment. The treatment must not be interrupted under
                  any circumstances, during the entire time prescribed.
                </p>
                <p>
                  It is completely forbidden to combine the Cosmelan
                  Depigmentation treatment with other Depigmentation treatments,
                  as the appearance of undesired effects cannot be guaranteed.
                </p>
                <p>
                  Treatment control visits are recommended 8 days after
                  treatment, 15 days after, and a follow up visit every month
                  thereafter.
                </p>
                <h5>WHAT CAN BE EXPECTED AFTER A COSMELAN TREATMENT MASK?</h5>
                <p>First and second day</p>
                <p>
                  During the first two days of the treatment, the skin may turn
                  red. If the skin is very sensitive, the patient may experience
                  an itching feeling. Slight burning and tightening of the skin
                  is also absolutely normal, especially during the first 24 to
                  48 hours after starting the treatment. To relieve these
                  problems, it is advisable to apply the creams advised.
                </p>
                <h5>Redness of the Skin</h5>
                <p>
                  The reddening that occurs on the face after applying the
                  Cosmelan mask treatment is similar to the effects of day-long
                  exposure to the sun in the snow without protection.
                </p>
                <p>
                  Flaking may occur on all skin types between the second and
                  third day. In this case it is advisable to apply cream
                  advised, repeating this every two hours on the day when the
                  flaking is most intense until it improves. Do not pull on the
                  flakes. Do not rub or scratch the skin. Do not use exfoliants,
                  scrubs or peels. Avoid steam baths, saunas and swimming in a
                  hot spring for 2 weeks following treatment. It is advisable to
                  wait until the initial flaking period is over before starting
                  to apply make-up again.
                </p>
                <p>Third and fourth day</p>
                <p>
                  After the third or fourth day, the skin appears healthy and
                  luminous and the marks begin to fade.
                </p>
                <h5>
                  WHAT CAN BE EXPECTED AFTER THE HOME APPLICATION OF COSMELAN 2?
                </h5>
                <p>HOME TREATMENT – Cosmelan Maintenance Cream</p>
                <p>
                  The second phase of the Cosmelan consists of a home
                  maintenance cream Cosmelan with the intention of securing the
                  Depigmentation by association of agents such as Kojic Acid,
                  Phytic Acid, and Vitamin C. We have added a physical agent to
                  block the sun and guarantee that the UV rays do not keep on
                  aggravating unwanted damage to skin though the sunscreen
                  advised has to be used three times a day.
                </p>
                <h5>
                  WHAT DOES THE STABILIZATION PERIOD OF A COSMELAN
                  DEPIGMENTATION TREATMENT MEAN?
                </h5>
                <p>
                  The period following the initial 6 month treatment process, is
                  called the STABILISATION period, and this can ideally be
                  extended up to one year. Every effort should be made to
                  continue treatment throughout the first summer. Even if the
                  treatment is a great success, long term success depends on how
                  well the protocol is followed, during both the treatment and
                  stabilization phases. VERY GOOD sun block is essential at all
                  times which protect against both uva and uvb radiations.
                </p>
                <h5>WHAT ARE IMPORTANT INSTRUCTIONS BEFORE USING COSMELAN?</h5>
                <p>
                  IMPORTANT NOTE: The treatment results are largely dependent on
                  your cooperation and adherence to the post treatment skin
                  care. The first 4 months constitute the treatment period. The
                  period following is called the stabilisation period, and this
                  is ideally extended up to one year, or longer. Every effort
                  should be made to continue treatment throughout the first
                  summer. Even if the Depigmentation treatment was a great
                  success, you need to understand that long term success depends
                  on how well the protocol is followed, during both the
                  treatment and stabilization phases. Avoid sun exposure for 2
                  weeks prior to treatment.
                </p>
                <h5>IS COSMELAN COMBINED WITH OTHER TREATMENTS?</h5>
                <p>
                  No peels can be done 2 weeks prior to treatment No Laser
                  treatment can be done on area to be treated 2 days prior to
                  peel ,Avoid and Filler in area to be treated 2 weeks prior to
                  peel.
                </p>
              </div>
            </div>

            <div className="col-lg-3">
              <SideBar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CosmelanDepigmentationTreatment;
