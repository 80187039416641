import React, { useLayoutEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import SideBar from "./SideBar";

const SkinCareSmoking: React.FC = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <div className="section section-lg bg-primary container-top">
        <div className="container ">
          <div className="text-center ">
            <h1>Skin Care & Smoking</h1>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-9">
              <div className="mb-5">
                <p>
                  Smoking changes the skin, teeth, and hair in ways that can add
                  years to your looks. It also affects fertility, the strength
                  of your heart, lungs, and bones. The distinctive
                  characteristics of a smoker's face, that generally make people
                  look older, are referred to as ""smoker's face"".
                </p>

                <h5>
                  A ""SMOKER'S FACE"" WILL HAVE ONE OR MORE OF THE FOLLOWING
                  CHARACTERISTICS
                </h5>
                <p>
                  Lines or wrinkles on the face, typically radiating at right
                  angles from the upper and lower lips or corners of the eyes,
                  deep lines on the cheeks, or numerous shallow lines on the
                  cheeks and lower jaw.
                </p>
                <p>
                  A subtle gauntness of facial features with prominence of the
                  underlying bony contours. Early smoker's skin changes show as
                  slight sucked in cheeks. In some cases these changes are
                  associated with a leathery, worn, or rugged skin texture. A
                  thin skin which gets a slightly pigmented grey appearance over
                  time.
                </p>

                <h5>SYMPTOMS OF SMOKER'S SKIN</h5>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Increased facial wrinkling
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Gray overall skin appearance and leathery texture
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Puffiness on the face
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Prematurely older appearance
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Irregularly thickened skin
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Yellowed fingers
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Loss of skin moisture content causing dryness
                  </p>
                </p>

                <h5>EFFECTS OF SMOKING ON THE SKIN</h5>
                <p>
                  Tobacco smoke exposure decreases capillary and arteriolar
                  blood flow, possibly damaging connective tissue that helps
                  maintains healthy skin. The cells that form collagen and
                  elastin the building blocks of the skin are also damaged by
                  cigarette smoke. Tobacco smoke is phototoxic, and its toxicity
                  increases in the presence Of UV rays from the sun, causing
                  double the damage to skin cells. Smoking can lead to the skin
                  getting fewer nutrient supplies and increasing the build of
                  toxic waste products in it.
                </p>

                <p>
                  Aging – There are more than 4,000 chemicals in tobacco smoke,
                  and many trigger the destruction of collagen and elastin –
                  fibers that give skin its strength and elasticity. Smoking or
                  even being around smokers degrades the building blocks of the
                  skin resulting in sagging skin and deeper wrinkles. The
                  nicotine in the cigarettes causes blood vessels to constrict,
                  weakening the supply of blood to the outer layer of the skin.
                </p>
                <p>
                  Pale skin tone and colour – Smoking deprives the skin of
                  oxygen and nutrients. So some smokers appear pale, while
                  others develop uneven skin color. Age spots or blotches of
                  darker skin color are common on the face and hands. While
                  anyone can develop these spots from too much sun, research
                  suggests that smokers are more susceptible. Smoking can also
                  lead to a condition called telangiectasia, characterised by
                  fine red lines in the skin caused by dilation of small blood
                  vessels. While holding a cigarette between your fingers may
                  look fashionable, over a period of time your fingernails and
                  the skin surrounding it gets stained by the tobacco and so do
                  the teeth. The good news is that these stains tend to fade
                  when you quit smoking.
                </p>
                <p>
                  Low moisture content – Smoking reduces the skin's moisture as
                  well as Vitamin A which is required for neutralising free
                  radical damage. This eventually causes skin dryness. Poor
                  wound healing – Smoking decreases the ability of the skin to
                  regenerate and repair wounds. Nicotine, one of the toxic
                  components of tobacco smoke causes blood vessels to contract,
                  reducing the nutrient supply to the body necessary for skin
                  regeneration. Carbon Monoxide is another toxic tobacco smoke
                  component that reduces oxygen flow through the body, causing a
                  build-up of toxic waste, thus preventing damaged cells from
                  regenerating. Lines around the lips – Smoking affects the area
                  around the mouth. Firstly, it creates ""the smoker's pucker"",
                  as smokers use certain muscles around their lips that give
                  them dynamic wrinkles that non-smokers do not have. Secondly,
                  there is a loss of elasticity. Both these factors lead to deep
                  lines around the lips.
                </p>
                <p>
                  Eye wrinkles (crow's feet) – Eventually everyone gets wrinkles
                  on the outside of the eyes, but these wrinkles develop earlier
                  and go deeper among smokers. Heat from burning cigarettes and
                  squinting to keep smoke away from the eyes contribute to
                  visible crow's feet. Meanwhile, chemicals from inhaled tobacco
                  cause internal damage to the skin structures and blood vessels
                  around the eyes.
                </p>
                <p>
                  Psoriasis – Psoriasis is a chronic condition that most often
                  causes thick, scaly patches on the skin – – usually on the
                  knees, elbows, scalp, hands, feet, or back. The patches may be
                  white, red or silver. Recent studies suggest smokers are at
                  greater risk of developing psoriasis.
                </p>
                <h5>PREVENTION</h5>
                <p>
                  Stop smoking – Quitting smoking is one of the best ways to
                  improve skin damaged by smoking. As the blood flow improves,
                  your skin receives more oxygen and nutrients, helping you
                  develop a healthier complexion.
                </p>
                <p>
                  Maintain a skincare routine – Once you quit smoking; the skin
                  becomes more resistant to premature aging. Wrinkles and age
                  spots, caused due to smoking, can be reversed by the use of
                  creams containing topical retinoid and antioxidants such as
                  Vitamins C and E. Sunscreen should also be used every day.
                </p>
                <p>
                  Use oral antioxidants – These can help limit the damage caused
                  by smoking. Observe the tobacco-free effect – Staying away
                  from tobacco will also reduce the stains on your fingers and
                  nails; teeth may get whiter.
                </p>
              </div>
            </div>

            <div className="col-lg-3">
              <SideBar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SkinCareSmoking;
