import React, { useLayoutEffect } from "react";
import BlogImg1 from "../../../assets/massage-2-390x344.jpg";
import UserImg from "../../../assets/user.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faComment } from "@fortawesome/free-regular-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import SideBar from "./SideBar";

const BodyThreadLift: React.FC = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <div className="section section-lg bg-primary container-top">
        <div className="container ">
          <div className="text-center ">
            <h1>Body Thread Lift</h1>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-9">
              <div className="mb-5">
                <h5>
                  Body Thread Lift: Non-Surgical Skin Lifting and Contouring
                </h5>
                <p>
                  At Skin & You Clinic, our Body Thread Lift treatment offers a
                  non-surgical solution for lifting and tightening loose skin on
                  various areas of the body. This advanced procedure provides a
                  subtle yet effective lift, helping to restore firmness and
                  contour without the need for invasive surgery or lengthy
                  downtime.
                </p>

                <h5>How It Works:</h5>

                <p>
                  The Body Thread Lift uses biocompatible, dissolvable threads
                  that are carefully inserted under the skin to lift and support
                  sagging tissue. These threads are designed to create an
                  immediate lifting effect while stimulating the body’s natural
                  collagen production over time. As the threads gradually
                  dissolve, the collagen continues to provide long-lasting
                  support and firmness to the treated area.
                </p>

                <h5>Treatment Areas:</h5>

                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Arms: To lift and tighten sagging skin on the upper arms.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Abdomen: To improve skin laxity after weight loss or
                    pregnancy.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Thighs: To contour and firm the inner and outer thighs.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Buttocks: To achieve a more lifted and toned appearance.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Knees: To reduce sagging skin around the knee area.
                  </p>
                </p>

                <h5>The Procedure:</h5>
                <p>
                  The Body Thread Lift procedure involves inserting the threads
                  into the deeper layers of the skin using fine needles. The
                  threads are strategically placed to lift the skin and create a
                  natural contour. The procedure typically takes 60 to 90
                  minutes, depending on the size of the treatment area and the
                  number of threads used. Local anesthesia is applied to ensure
                  comfort during the treatment.
                </p>

                <h5>Results:</h5>

                <p>
                  You can expect to see an immediate lift in the treated area,
                  with continued improvements in skin firmness and elasticity as
                  the collagen stimulation process takes effect. The results are
                  natural-looking and can last from 12 to 18 months, depending
                  on individual factors and lifestyle.
                </p>

                <h5>Possible Side Effects:</h5>

                <p>
                  The Body Thread Lift is generally well-tolerated, with mild
                  side effects such as temporary swelling, bruising, or slight
                  discomfort at the insertion sites. These typically resolve
                  within a few days. In rare cases, minor irregularities or
                  asymmetry may occur, which can be adjusted by your
                  practitioner.
                </p>

                <h5>
                  Why Choose Skin & You Clinic for Body Thread Lift Treatments?
                </h5>

                <p>
                  At Skin & You Clinic, we offer the Body Thread Lift as a safe
                  and effective alternative to surgical body contouring. Our
                  experienced professionals are skilled in the latest thread
                  lifting techniques, ensuring precise application and optimal
                  results tailored to your body’s unique needs. We are committed
                  to helping you achieve a more youthful and toned appearance
                  with minimal disruption to your daily life.
                </p>
              </div>
            </div>

            <div className="col-lg-3">
              <SideBar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BodyThreadLift;
