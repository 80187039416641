import React, { useLayoutEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import SideBar from "./SideBar";

const ExcessiveSweating: React.FC = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <div className="section section-lg bg-primary container-top">
        <div className="container ">
          <div className="text-center ">
            <h1>Excessive Sweating</h1>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-9">
              <div className="mb-5">
                <p>
                  This section will definitely interest you if you answer yes to
                  any of the following questions
                </p>

                <h5>“Do you feel you sweat more than other people?”</h5>
                <h5>
                  “Does a five-minute workout on the treadmill leave you sopping
                  wet?”
                </h5>
                <h5>“Do you wipe your hand before every handshake?”</h5>
                <h5>ABOUT EXCESSIVE SWEATING</h5>
                <p>
                  Hyperhydrosis is a medical condition in which a person sweats
                  excessively and unpredictably. Generally, people sweat more in
                  warm temperatures, when they exercise, or in response to
                  situations that make them nervous, angry, embarrassed or
                  afraid. However, excessive sweating occurs without such
                  triggers. Those with hyperhydrosis appear to have overactive
                  sweat glands. The uncontrollable sweating can lead to
                  significant discomfort, both physical and emotional. It
                  usually occurs in areas like the palms, soles, armpits, and/or
                  groin area.
                </p>

                <h5>TYPES AND CAUSES</h5>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Focal Hyperhydrosis
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    It affects the palms, soles and sometimes underarms.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    It often occurs as an exaggerated response to emotional
                    stimuli and is not generally associated with any underlying
                    condition.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Secondary/Generalized hyperhydrosis
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    It affects large areas of the body.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Underlying causes could be – A reaction to certain
                    medications, menopausal hot flushes, low blood sugar, an
                    overactive thyroid (hyperthyroidism), rarely certain
                    cancers, heart disease and infectious diseases.
                  </p>
                </p>

                <p>Symptoms</p>

                <p>
                  Frequent, noticeable, excessive sweating that can soak through
                  clothing
                </p>
                <p>
                  Abnormally excessive and bothersome perspiration on your feet,
                  underarms, head or face
                </p>
                <p>
                  Clamminess or dripping of sweat droplets on the palms of the
                  hands or the soles of your feet Diagnosis
                </p>

                <p>
                  Visible signs of sweating may be noted during a doctor’s
                  visit. A number of tests may also be used to diagnose
                  excessive sweating. These are-
                </p>

                <p>
                  Starch-Iodine Test – An iodine solution is applied to the
                  sweaty area. After it dries, starch is sprinkled on the area.
                  The starch-iodine combination turns a dark blue colour
                  wherever there is excess sweat.
                </p>

                <p>
                  Paper Test – Special paper is placed on the affected area to
                  absorb the sweat, and then weighed. The heavier it weights,
                  the more sweat has accumulated.
                </p>

                <h5>DATA COLLECTION</h5>
                <p>
                  The doctor will also require other details from you to
                  determine the exact cause, like
                </p>

                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Location- Does it occur on your face, palms, or armpits, or
                    all over the body?
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Time pattern- Does it occur at night or does it begin
                    suddenly?
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Triggers- Does the sweating occur when you are reminded of a
                    traumatic or emotionally disturbing event?
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Other noted symptoms- Weight loss, pounding heartbeat, cold
                    or clammy hands, fever, lack of appetite
                  </p>
                </p>

                <h5>TREATMENTS AT SKIN & YOU CLINIC FOR HYPERHIDROSIS</h5>
                <p>
                  Antiperspirants –Some patients may be prescribed a product
                  containing a higher dose of aluminium chloride to be applied
                  at night or as prescribed by dermatologist. Antiperspirants
                  can cause skin irritation, and large doses of aluminium
                  chloride can damage clothing. Deodorants do not prevent
                  sweating, but are helpful in reducing body odor.
                </p>

                <p>
                  Medication – Certain anticholinergics help to prevent the
                  stimulation of sweat glands.
                </p>

                <p>
                  Botox – Botulinum toxin type A (Botox) is FDA approved for the
                  treatment of severe underarm sweating, a condition called
                  primary axillary hyperhydrosis. Small doses of purified
                  botulinum toxin injected into the underarm temporarily block
                  the nerves that stimulate sweating. Side effects include
                  injection-site pain. If you are considering Botox for other
                  areas of excessive sweating talk to your doctor in detail.
                  Botox used for sweating of the palms can cause mild but
                  temporary weakness and pain.
                </p>

                <h5>COMPLICATIONS</h5>
                <p>
                  Some of the causes of Hyperhydrosis can be serious. Always
                  consult a doctor if you have excessive sweating. Please inform
                  us @ Skin & You Clinic, Mumbai if you have
                </p>

                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Prolonged, excessive, and unexplained sweating
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Sweating with or followed by chest pain or pressure
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Sweating with weight loss
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Sweating that most often occurs during sleep
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Sweating with fever, weight loss, chest pain, shortness of
                    breath, or a rapid, pounding heartbeat as these symptoms may
                    be a sign of an underlying disease, such as hyperthyroidism.
                  </p>
                </p>

                <h5>WHY SKIN & YOU CLINIC FOR EXCESSIVE SWEATING?</h5>
                <p>
                  At Skin & You Clinic, we understand cosmetic concern due to
                  excessive sweating. After careful history taking, examination
                  and ruling out any systemic or local causes, we plan treatment
                  to provide long term treatment care for the patients.
                </p>
              </div>
            </div>

            <div className="col-lg-3">
              <SideBar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExcessiveSweating;
