import React, { useLayoutEffect } from "react";
import BlogImg1 from "../../../assets/massage-2-390x344.jpg";
import UserImg from "../../../assets/user.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faComment } from "@fortawesome/free-regular-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import SideBar from "./SideBar";

const NonSurgicalSkinTightening: React.FC = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <div className="section section-lg bg-primary container-top">
        <div className="container ">
          <div className="text-center ">
            <h1>Non-Surgical Skin Tightening</h1>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-9">
              <div className="mb-5">
                <p>
                  At Skin & You Clinic, we offer cutting-edge skin tightening
                  treatments designed to rejuvenate your skin, restoring its
                  youthful firmness and elasticity. Whether you're concerned
                  about sagging skin on your face, neck, or body, our advanced
                  non-surgical treatments effectively target loose skin, helping
                  you achieve a more toned and lifted appearance. With minimal
                  downtime and long-lasting results, skin tightening is the
                  perfect solution for those looking to refresh their appearance
                  without undergoing surgery.
                </p>

                <h5>How It Works:</h5>
                <p>
                  Our skin tightening treatments use advanced radiofrequency
                  (RF) or ultrasound technology to penetrate deep into the
                  skin's layers. This energy heats the tissue beneath the skin,
                  stimulating the production of collagen and elastin, the
                  proteins responsible for skin’s strength and elasticity. Over
                  time, as new collagen is produced, the skin becomes firmer,
                  smoother, and tighter. The treatment also promotes cell
                  regeneration, further enhancing the skin’s overall appearance.
                </p>

                <h5>Treatment Areas:</h5>

                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Face: Jawline, cheeks, forehead, and around the eyes.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Neck: Sagging skin or "turkey neck".
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Body: Abdomen, thighs, arms, and any area where skin laxity
                    is a concern.
                  </p>
                </p>

                <h5>The Procedure:</h5>
                <p>
                  Skin tightening treatments are typically quick, lasting
                  between 30 to 60 minutes depending on the area being treated.
                  During the procedure, a handheld device is applied to the
                  skin, delivering controlled energy to the target areas. Most
                  patients find the treatment comfortable, with a warm sensation
                  as the energy is delivered. There is no downtime, so you can
                  return to your daily activities immediately after the session.
                </p>

                <h5>Results:</h5>
                <p>
                  You may notice some immediate tightening effects, but the most
                  significant results appear gradually over the weeks and months
                  following the treatment, as collagen production increases. For
                  optimal results, a series of treatments is recommended,
                  depending on your skin's condition and your aesthetic goals.
                  The result is a natural, youthful appearance with smoother,
                  tighter skin that continues to improve over time.
                </p>

                <h5>Possible Side Effects:</h5>
                <p>
                  Skin tightening treatments are non-invasive and generally
                  well-tolerated. Some patients may experience mild redness or
                  slight swelling in the treated areas, but these side effects
                  are temporary and typically resolve within a few hours.
                </p>

                <h5>Why Choose Skin & You Clinic for Skin Tightening?</h5>
                <p>
                  At Skin & You Clinic, we are committed to providing the latest
                  and most effective skin tightening solutions. Our team of
                  experienced professionals tailors each treatment to your
                  unique skin type and concerns, ensuring the best possible
                  outcome. With our advanced technology and personalized care,
                  you can trust us to help you achieve a firmer, more youthful
                  appearance with results that last.
                </p>
              </div>
            </div>

            <div className="col-lg-3">
              <SideBar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NonSurgicalSkinTightening;
