import React, { useLayoutEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import SideBar from "./SideBar";

const SkinRejuvenationTreatment: React.FC = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <div className="section section-lg bg-primary container-top">
        <div className="container ">
          <div className="text-center ">
            <h1>Skin Rejuvenation Treatment</h1>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-9">
              <div className="mb-5">
                <h5>The goals of the treatment are –</h5>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    To improve the general quality of skin
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    To achieve a general reduction in the facial wrinkles
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    To remove a few benign lesions from the face (non-pigmented
                    naevi/moles/tags)
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    To reduce expression lines, in particular frown and forehead
                    lines
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    To replace lost facial volume
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    To remove pigmentation or brown marks from skin
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    RF causes lifting and tightening and results in a tighter,
                    recontoured face, smoothening lines and wrinkles
                  </p>
                </p>

                <h5>WHAT IS TOTAL SKIN REJUVENATION?</h5>
                <p>
                  Total facial rejuvenation is a phrase that describes a
                  cosmetic treatment that addresses all aspects of the aging
                  process. This results in a more complete skin rejuvenation
                  plan.
                </p>

                <h5>The effects of skin aging include</h5>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Facial volume loss
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Skin sagging and laxity
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Expression line formation
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    General skin deterioration
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Wrinkling
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Formation of brown spots/pigmentation
                  </p>
                </p>

                <h5>
                  NON-SURGICAL TECHNIQUES CAN SUCCESSFULLY TACKLE THESE ISSUES
                  (For more information on Deep chemical peel )
                </h5>
                <p>
                  Facial volume loss – It is one of the first signs of ageing;
                  facial volume loss occurs in the cheeks, around the eyes,
                  brows, lips, around the mouth, chin, nasolabial folds and
                  laugh lines, giving the skin a tired look. Dermal fillers are
                  used to replace the volume that has naturally been lost over
                  time. They enhance and balance particular areas of the face,
                  like, making the chin more prominent and better aligned with
                  the rest of the face. This process of replacing facial volume
                  for rejuvenation or enhancement is often termed as "non
                  surgical facial shaping".
                </p>
                <p>
                  Skin sagging or laxity – Skin tends to lose its elasticity and
                  become more lax with time. Facial skin sagging can also be
                  addressed non-surgically
                </p>
                <p>
                  Dermal fillers can be used to lift the face and skin through
                  strategic placement in the face. They also help smoothen the
                  transition from one facial area to another so as to give the
                  face a more aesthetic and even appeal. For example, by filling
                  the under eye hollows (or tear troughs), the transition from
                  the lower eyelid to the cheek is streamlined. This process
                  results in a more refreshed appearance and hides the effect of
                  gravity on the skin.
                </p>
                <p>
                  Radiofrequency (RF) – is a non-invasive, no downtime
                  treatment, that is used for skin tightening. It uses
                  radiofrequency energy to heat the skin to a temperature
                  between 39 and 42 degrees celsius. This helps to shrink skin
                  collagen and remodel the skin, resulting in skin tightening.
                </p>
                <p>
                  Expression lines – These are another part of the ageing
                  process that can be addressed non-surgically. They are
                  wrinkles and lines that get indented and appear even at rest.
                  They result from the contraction of muscles while making
                  facial expressions. With time, these can cause etching of
                  lines in the skin, such as frown lines or crow's feet. These
                  are simply addressed with Botox injections that relax select
                  muscles and maintain a natural look.
                </p>
                <p>
                  Surface wrinkles – These are etched in on the skin, i.e.
                  present even at rest, and are best treated with skin
                  resurfacing. This can be achieved by the RF pixel or deep
                  chemical peel. The radiofrequency actually ablate (vapourise)
                  a very fine layer of the skin surface, allowing the skin to
                  regenerate with fewer wrinkles. There is usually a week or two
                  of recovery time, depending on the intensity of the treatment.
                  The RF pixel also improves brown spots and general skin
                  texture/tone and is safe on Asian skin. Skin wrinkles can also
                  be treated with less invasive methods, such as medical grade
                  skincare (including topical retinoid). As a rule, the more
                  invasive a treatment, the greater the downtime, and better the
                  results.
                </p>
                <p>
                  Pigmentation – Finally, the effects of cumulative UV exposure
                  on the skin surface can lead to skin wrinkling, formation of
                  brown spots, and the appearance of broken capillaries. These
                  can be treated with
                </p>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Gel Peels
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    CACI Treatment
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Mesotherapy Treatment
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Alma Photo Facial
                  </p>
                </p>

                <h5>WHY SKIN & YOU CLINIC FOR TOTAL FACIAL REJUVENATION?</h5>
                <p>
                  The doctors at Skin & You Clinic, Mumbai, specialize in
                  non-surgical total facial rejuvenation. We understand that by
                  addressing all areas of concerns like pigmentation, lines and
                  wrinkles, lax skin and facial volume loss, we can restore the
                  complete fountain of youth to the face. In consultation with
                  the patient, depending on his/her expectations, concerns and
                  required downtime, we formulate realistic goals to achieve the
                  best possible result.
                </p>
              </div>
            </div>

            <div className="col-lg-3">
              <SideBar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SkinRejuvenationTreatment;
