import React, { useLayoutEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import SideBar from "./SideBar";

const StretchMarksTreatment: React.FC = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <div className="section section-lg bg-primary container-top">
        <div className="container ">
          <div className="text-center ">
            <h1>Stretch Marks Treatment</h1>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-9">
              <div className="mb-5">
                <p>
                  Stretch marks are tears in the dermis (middle layer of the
                  skin) causing
                </p>

                <p>
                  Loss and damage of collagen and elastin (building blocks of
                  the skin), blood vessels and pigment producing cells in the
                  area affected.
                </p>
                <p>
                  In addition, one also sees a loss of skin tone and wrinkle
                  formation.
                </p>
                <p>
                  Types of scars that, are initially red to purple in colour,
                  and then, with time, gradually fade to a pale to white
                  glistening colour.
                </p>

                <h5>Occurrence</h5>

                <p>
                  About 90% of pregnant women get stretch marks. They also
                  affect 70% of young females and about 40% of young males.
                </p>

                <h5>TRIGGERS</h5>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Heredity – Some individuals are genetically prone to stretch
                    marks.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Weight – Rapid weight gain or loss.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Skin Type – Dryer skin types have less elasticity than oily
                    skin so dry skin types are prone to stretch marks. Use of
                    moisturiser regularly can prevent stretch marks in dry skin
                    individuals.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Pregnancy – it can result from the weight gain and hormone
                    activity during pregnancy.
                  </p>
                </p>

                <h5>COMMONLY SEEN IN</h5>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Pregnant women (last months of pregnancy, on lower abdomen).
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Teenagers during growth spurts.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Individuals that gain rapid or excessive weight.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Body builders, weightlifters.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Individuals taking drugs containing steroids.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Young women, on their breasts, thighs, hips and buttocks.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    In people who are generally more susceptible to stretch
                    marks.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Preventing Stretch Marks
                  </p>
                </p>

                <h5>STRETCH MARK PREVENTION MEASURES MAY INCLUDE</h5>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Avoiding excessive weight gain.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Eating a well-balanced healthy diet rich in antioxidants,
                    vitamins and minerals; drinking enough water and exercising
                    regularly can help.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Moisturising the skin regularly.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Avoiding steroid medications
                  </p>
                </p>

                <h5>TREATMENTS AT SKIN & YOU CLINIC FOR STRETCH MARKS</h5>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Various creams and gels can be advised that help in
                    lightening and blending of the stretch marks.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    RF pixel laser treatment safe for all skin types
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    RF Pixel laser, based on the RF-based technology, that
                    resurfaces the skin, is a minimally invasive skin barrier
                    breakthrough treatment for skin repair and aesthetic
                    enhancement.
                  </p>
                </p>

                <p>
                  Its synergy of technologies provides patients with a quick,
                  easy and virtually pain-free answer to stretch marks. The RF
                  Pixel uses micro-plasma technology to create multiple
                  controlled micro-channels that reach beyond superficial layer
                  of the skin, creating a local heat injury zone, leading to
                  skin resurfacing by the lasers. The remaining tissue aids the
                  healing and generation of new collagen. Eventually the
                  irregular epidermal pigmentation is eliminated and collagen is
                  formed, leading to smoothened and tightened skin and removal
                  of stretch marks.
                </p>

                <p>
                  RF Pixel enables doctors and aesthetic practitioners to obtain
                  continuous and section by section skin resurfacing. It rolls
                  across the skin, delivering the laser pulses easily with no
                  discomfort.
                </p>

                <p>
                  Depth of skin resurfacing varies with energy output and can
                  reach up to several hundred microns, while the controlled
                  spacing between pixels minimizes the damage on the treatment
                  area, thereby strengthening collagen as well as reducing
                  patient downtime.
                </p>

                <h5>BENEFITS OF RF PIXEL</h5>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Effective skin resurfacing
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Enhanced treatment results
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Works on all skin types
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Safe for use all year round
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    60-75% reduction in the stretch marks at the end of 3-5
                    sessions
                  </p>
                </p>

                <h5>WHY SKIN & YOU CLINIC FOR STRETCH MARK REMOVAL?</h5>
                <p>
                  Skin & You Clinic, Mumbai, has the latest technology in
                  stretch mark removal that can rapidly treat large areas
                  without the limitations of older technologies. The unique RF
                  pixel treatment is safe, has reduced downtime and minimal side
                  effects.
                </p>

                <p>
                  We specialize in stretch mark removal obtaining almost a 60-
                  75 % reduction following treatment.
                </p>
              </div>
            </div>

            <div className="col-lg-3">
              <SideBar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StretchMarksTreatment;
