import React, { useLayoutEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import SideBar from "./SideBar";

const UnwantedSkinGrowths: React.FC = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <div className="section section-lg bg-primary container-top">
        <div className="container ">
          <div className="text-center ">
            <h1>Unwanted Skin Growths</h1>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-9">
              <div className="mb-5">
                <h5>UNWANTED SKIN GROWTHS</h5>
                <p>
                  Youthful skin is smooth, firm, evenly coloured with no growth.
                  However, skin is the first area to show signs of aging.
                  Unwanted growth on the skin not only looks unsightly but makes
                  us look unattractive. This can be treated by Radio wave
                  Technology that uses energy to make cosmetic enhancing
                  incisions. Healing is rapid, virtually bloodless and with
                  little or no bruising or swelling.
                </p>

                <h5>
                  Few of the other commonly encountered unwanted growths are:
                </h5>

                <p>
                  SKIN TAGS/ ACROCHORDONS: These are soft, pedunculated, skin
                  coloured to brownish lesions present over neck, axillae,
                  groin, eyelids and inner thighs usually. More commonly seen in
                  overweight individuals, in elderly and middle- age group.
                </p>

                <p>
                  MILIA: they are asymptomatic, tiny, and white or skin colored
                  raisedsubepidermal cysts,seen in all age groups around eyes
                </p>

                <h5>skin-growth-1</h5>

                <p>
                  DERMATOSIS PAPULOSA NIGRICANS (DPNS): DPN’s are more common in
                  dark- skinned individuals. It appears as multiple
                  brownish-black asymptomatic, small, smooth round raised
                  lesions mainly around eyes, sometimes over neck or trunk can
                  be seen.
                </p>

                <h5>skin-growth-2</h5>

                <p>
                  MOLE (MELANOCYTIC NAEVUS): It is commonly seen in first two
                  decades of life, sometimes it is present since birth. They are
                  dark brownish black flat or raised smooth lesion. They may
                  change slowly, becoming raised, changing color or gradually
                  fading over the course of time.
                </p>

                <p>
                  Syringoma: It is fairly common conditions seen in
                  femalespredominantely. They are multiple, 1-3 mm,
                  skin-colored, dome shaped, flat raised lesions around eyes.
                </p>

                <h5>WHY SKIN & YOU CLINIC FOR SKIN GROWTHS?</h5>
                <p>
                  We at Skin and You clinic, use a painless removal technique
                  for all the unwanted growth and with minimal downtime with our
                  supreme RF cautery machine.
                </p>

                <h5>RADIO FREQUENCY GROWTH REMOVAL with cautery</h5>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Radio wave technology treats – Many types of skin lesions
                    like
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Age spots
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Moles and raised lesions
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Skin tags
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Warts (verruca)
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Sun damaged skin
                  </p>
                </p>

                <p>
                  About radio wave technology – It is the passage of ultra-high
                  frequency radio waves into skin for the purpose of eliminating
                  lesions with minimal discomfort and rapid healing.
                </p>

                <p>
                  Radio wave technology vis-à-vis traditional surgery – With
                  radio waves there is no need of cutting or laser; thus there
                  is less bleeding because of cutting and is more comfortable
                  than lasers.
                </p>

                <h5>ADVANTAGES OF RADIOSURGERY –</h5>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Virtually no risk of scarring, removal occurs at a very
                    superficial level
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Better and faster healing
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Minimal damage to surrounding skin
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Minimal, if any, pain
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Less swelling and oedema, if any
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    No bleeding
                  </p>
                </p>

                <p>
                  How the treatment area heals – Immediately after excision
                  there is simply a raw non-bleeding surface not bigger than the
                  original surface area of the lesion. This forms a scab and
                  after 7-10 days later, there is a fresh healthy layer of skin
                  which overtime blends into normal skin colour.
                </p>

                <h5>AREAS THAT CAN BE TREATED ARE</h5>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Face – This technique is particularly useful for facial
                    growth and spots as there is no scarring and the healing
                    period is short.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Armpits/ breasts/ neck – These are common sites of fleshy
                    growths called skin tags that can be removed by this
                    technique.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Hands – Removal of brown spots, solar Keratoses, other
                    growths
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Number of sessions required – A single session is usually
                    required. This should be followed up after 7 days.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Benefits of the Treatment – It is safe and proven with
                    minimal downtime.
                  </p>
                </p>

                <h5>WHY SKIN & YOU CLINIC FOR REMOVAL OF UNWANTED GROWTHS?</h5>
                <p>
                  We at Skin & You Clinicuse the safest technology with minimal
                  downtime and no side effects. We understand that less healing
                  time with even skin with no unwanted growths is desired by all
                  of us.
                </p>
              </div>
            </div>

            <div className="col-lg-3">
              <SideBar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnwantedSkinGrowths;
