import React, { useLayoutEffect } from "react";
import BlogImg1 from "../../../assets/massage-2-390x344.jpg";
import UserImg from "../../../assets/user.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faComment } from "@fortawesome/free-regular-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import SideBar from "./SideBar";

const NDYAGHairRemovalTreatment: React.FC = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <div className="section section-lg bg-primary container-top">
        <div className="container ">
          <div className="text-center ">
            <h1>NDYAG / Hair Removal Treatment</h1>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-9">
              <div className="mb-5">
                <h5>
                  An alternative to threading, waxing, bleaching & electrolysis.
                </h5>

                <p>
                  Both men and women alike are making unwanted facial and body
                  hair a thing of the past – thanks to the laser hair removal
                  technology at Skin & You Clinic, Mumbai.
                </p>

                <h5>BENEFITS:</h5>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    No threading
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    No Waxing
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    No Bleaching
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Cool Chill tip for Pain Free Treatments.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    FAQS
                  </p>
                </p>

                <h5>
                  HOW IS THE LASER SYSTEM AT SKIN & YOU CLINIC DIFFERENT FROM
                  OTHERS?
                </h5>
                <p>
                  The laser system at Skin & You Clinic, Mumbai, is one of the
                  best laser hair removal systems available in the market. It
                  can be used on all skin types and tones, from light to dark,
                  including tanned patients. Its unique design, longer
                  wavelength and innovative cooling allows for extremely
                  effective, safe and permanent results.
                </p>

                <h5>WHAT ARE THE OPTIONS FOR HAIR REMOVAL?</h5>
                <p>
                  A faster, proven, reliable and more sophisticated method for
                  most is laser hair removal. This has been medically tested.
                  The equipment used by Skin & You Clinic, Mumbai, is a
                  high-powered 1064 nm Ndyag laser system that provides safety
                  and speed; it is FDA approved. The Ndyag System can be used on
                  all skin types and tones as well as on tanned skin.
                </p>

                <h5>WHAT AREAS CAN BE TREATED WITH THE LASER SYSTEM?</h5>
                <p>
                  The laser can remove hair from all parts of the body – the
                  face, legs, arms, underarms and back. It can also treat
                  sensitive areas like the chest, nipples, and bikini line.
                </p>

                <h5>HOW DOES LASER HAIR REMOVAL WORK?</h5>
                <p>
                  Firstly, laser hair removal is a medical procedure. lasers
                  work on the color of the hair and on its thickness. An ideal
                  candidate would be someone with thick dark hair and light to
                  Wheatish skin color. Darker people can also benefit from this
                  procedure but a safer technology will have to be used for them
                  as their skin has higher amounts of melanin and is thus at a
                  higher risk of side effects. People with very light or white
                  hair benefit less or little from laser hair removal.
                </p>

                <h5>HOW MANY TREATMENTS ARE NEEDED?</h5>
                <p>
                  A number of factors affect the success of the treatment – hair
                  thickness, the area treated and your hair growth cycle.
                  Interestingly, not all your hair is growing actively at the
                  same time, and hair actually goes through three distinct
                  phases – growth, regression, and resting. The laser works by
                  disabling the hair that is principally in the active growth
                  phase at the time of treatment. Since other hairs will enter
                  this active growth phase at different times, additional
                  treatments may be necessary to disable all of the hair
                  follicles in a given area. About 10-25% reduction in hair
                  growth can be expected with each treatment. The hair that
                  grows following the treatment tends to be lighter and finer in
                  texture. About 6-8 treatments are generally required with
                  ongoing maintenance in some people.
                </p>

                <h5>DOES THE LASER TREATMENT HURT?</h5>
                <p>
                  The laser cools and soothes as it glides along your skin. When
                  the pulse of light is delivered, some patients experience a
                  mild pinching or stinging sensation. No local anaesthesia or
                  pain medication is required.
                </p>

                <h5>WHAT HAPPENS AFTER THE TREATMENT?</h5>
                <p>
                  Immediately following the treatment, the area may appear a bit
                  red and swollen. Three to seven days after the treatment, you
                  may experience what seems to be regrown hair. The hair is
                  actually just being shed as a result of your treatment and is
                  not really regrowing. Between treatments you should not wax or
                  pluck your hair, but it is permissible to shave the area.
                </p>

                <h5>ARE THERE ANY RESTRICTIONS AFTER THE TREATMENT?</h5>
                <p>
                  Following the treatment, you can return to your normal
                  activities right away, but some redness may occur for a few
                  hours. The use of sunscreen is recommended on any treated
                  areas exposed to the sun.
                </p>

                <h5>ARE THERE ANY SIDE EFFECTS?</h5>
                <p>
                  In trained hands, the side effects would be minimal or none
                  whatsoever. To mention a few unpleasant changes – one may see
                  redness, swelling and very rarely, blistering. Hence you may
                  need post-treatment care. But do not worry, the doctors at
                  Skin & You Clinic, Mumbai, will be by your side at every step.
                </p>

                <h5>WHAT CAN ONE DO TO PREVENT SIDE EFFECTS?</h5>
                <p>
                  A patch test can be done for sensitive or darker skin.
                  Pre-screening of patients is done only by dermatologists and
                  trained doctors. At Skin & You Clinic, Mumbai, we give you an
                  advisory list of pre and post do’s and don’ts to ensure
                  maximum protection and safety. We recommend you ask as many
                  questions as you have before starting the treatment.
                </p>

                <h5>AT WHAT AGE CAN IT BE DONE?</h5>
                <p>
                  One can start as early as 16-18 years or till you have dark
                  hair on your body. Younger girls can enjoy complete freedom
                  from unwanted hair within a year.
                </p>

                <h5>WHAT RESULTS SHOULD ONE EXPECT OF THE TREATMENT?</h5>
                <p>
                  You should see a reduction in the density and thickness of the
                  hair in the treated area. After you have completed your
                  treatment plan, long-term hair reduction could be achieved.
                  With hair removal as with any treatment, there are people that
                  fall in the grey zone. For example, people with hormonal
                  problems, light hair growth, white hair or those who have
                  experienced previously failed laser treatments need to be
                  evaluated and the reason for laser failure needs to be
                  investigated.
                </p>

                <h5>
                  WHY CHOOSE LASER AT SKIN & YOU CLINIC OVER OTHER LASER HAIR
                  REMOVAL SYSTEMS?
                </h5>
                <p>
                  Skin & You Clinic, Mumbai, has the latest technology in laser
                  hair removal which can rapidly treat large areas without the
                  limitations of older technologies. The longer wavelength
                  allows it to be used safely and effectively on patients with
                  light or dark skin as well as tanned skin. Unlike other
                  systems, laser can treat the fine hair on a woman’s upper lip
                  just as easily and effectively as the coarse hair on a man’s
                  back. The cooled hand piece increases patient comfort and
                  minimizes the adverse skin reaction commonly seen with other
                  lasers.
                </p>

                <h5>
                  WHY CHOOSE SKIN & YOU CLINIC AS YOUR HAIR REMOVAL PROVIDER?
                </h5>
                <p>
                  Skin & You Clinic, Mumbai, uses the safest technology
                  available. For mothers, the best advice to give their
                  daughters, for a hair-free cleaner looking skin, is laser hair
                  removal. We at Skin & You Clinic, Mumbai, conduct this medical
                  procedure in a hygienic environment with the expert support
                  and supervision of a dermatologist. It is a simple and safe
                  way of making your skin look hair-free, clean and smooth.
                </p>
              </div>
            </div>

            <div className="col-lg-3">
              <SideBar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NDYAGHairRemovalTreatment;
