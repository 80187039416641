import React, { useLayoutEffect } from "react";
import BlogImg1 from "../../../assets/massage-2-390x344.jpg";
import UserImg from "../../../assets/user.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faComment } from "@fortawesome/free-regular-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import SideBar from "./SideBar";

const BodySlimmingTreatment: React.FC = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <div className="section section-lg bg-primary container-top">
        <div className="container ">
          <div className="text-center ">
            <h1>Body Slimming Treatment</h1>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-9">
              <div className="mb-5">
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Arms: To lift and tighten sagging skin on the upper arms.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Abdomen: To improve skin laxity after weight loss or
                    pregnancy.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Thighs: To contour and firm the inner and outer thighs.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Buttocks: To achieve a more lifted and toned appearance.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Knees: To reduce sagging skin around the knee area.
                  </p>
                </p>

                <h5>
                  We at skin and you clinic achieve inch loss and weight loss
                  through different modes
                </h5>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    ILipo
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Accent Ultra Radio Frequency
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Diet and Nutrition
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />I
                    LIPO
                  </p>
                </p>

                <p>
                  The procedure takes around 30 to 35 mins it is completely safe
                  and painless treatment for targeted fat reduction
                </p>
                <p>
                  Inch loss varies from person to person but one can expect to
                  go down atleast by 2 dress sizes which has been documented.
                </p>

                <p>
                  The procedure works as low level of visible diode laser lights
                  which is painlessly absorbed by individual fat cell.the
                  content is broken into fatty acid which escapes through cell
                  wall and is easily burned down by body.
                </p>

                <h5>RADIO FREQUENCY ACCENT ULTRA</h5>
                <p>
                  The treatment is meant for body contouring with successful
                  results for inch loss,cellulite improvement and skin
                  tightening.
                </p>

                <p>
                  Virtually a pain free treatment with visible results in 2
                  weeks .the treatment feels like hot stone massage.when
                  combined with diet and exercise can reveal dramatic results
                  for a slimmer you.
                </p>

                <p>
                  Treatment is safe and painless and requires around 4 to 6
                  session at an interval of 10 days with each session lasting
                  upto 90 mins.
                </p>

                <p>The procedure uses two modules</p>

                <p>
                  Ultrasound which helps in melting the fat in the targeted area
                  and radiofrequency which helps tightening the skin which is
                  lax.the procedure helps by stimulation of new collagen .the
                  fat content of cells break down which gets excreted through
                  lymphatics.
                </p>

                <h5>LIPOLYSISINJECTION</h5>
                <p>
                  Cosmetic procedure in which a drug mixture of
                  phosphatidylcholine and deoxycholate is injected in the
                  targeted area where fat reduction is expected .the procedure
                  is done at a 3 week interval wheremultiple microinjections are
                  administered into the subcutaneous fat over multiple treatment
                  session
                </p>

                <p>
                  The desired end result is removal of localized fat deposit
                </p>

                <h5>DIET AND NUTRITION</h5>
                <p>
                  Skin and you specialises in giving diet for weight loss which
                  can gradually be made into a lifestyle change so as to help
                  you maintain the weight loss achieved.
                </p>

                <p>
                  Diet Advice – Have 5 proper meals a day; it should be a
                  balanced diet, with 5 portions of fruits and 2 portions of
                  vegetables. Avoid missing meals.
                </p>

                <p>
                  What do you will feel during the treatments – A comfortable,
                  relaxing treatment with a typical sensation of warmth.
                </p>

                <p>
                  Effectiveness of the treatment – Inch loss varies from
                  individual to individual. Generally a reduction of 4-6 inches
                  is seen in the patient. At least, one to two dress-size
                  reduction has been documented.
                </p>

                <p>
                  Maintenance plan – A diet advised by us should be followed. An
                  exercise routine also helps. If there is no weight gain,
                  maintenance treatments are seldom required.
                </p>

                <h5>ADVICE FOR INCH LOSS AND BODY SHAPING</h5>
                <h5>PRE TREATMENT PROTOCOL</h5>
                <p>
                  Please avoid heavy meals for 2-4 hours before and after each
                  treatment as the body gets confused about which fat to
                  metabolise. If you are hungry, munch on some fruit. Do not
                  fast before the treatment as the body may go into starvation
                  mode. Avoid coffee, tea and carbonated drinks before the
                  treatment as these may cause bloating. Good hydration is must
                  for a healthy lymphatic system, so make sure you drink enough
                  water. Avoid going in for the treatment around the time of
                  your menstruation cycle. To actually measure the results, wear
                  the same clothes on the last day and the first day of the
                  treatment.
                </p>
                <h5>POST TREATMENT PROTOCOL</h5>
                <p>
                  Post treatment, exercise is a must as it speeds up the
                  metabolic rate and helps use up the mobilized fat, thus
                  preventing it from being restored. Adequate hydration is a
                  must, both pre and post treatment. Avoid eating immediately
                  after the session. You may eat 2-3 hours after the treatment
                  Follow your diet routine as advised.
                </p>
                <p>
                  Your measurements are taken pre and post the treatment in
                  order to monitor the progress.
                </p>
                <h5>WHY SKIN & YOU CLINIC FOR BODY SLIMMING TREATMENTS?</h5>
                <p>
                  We at Skin & You Clinic understand your concerns all plans are
                  custom made as per indivivual needs and concerns,we help you
                  formulate and ideal routine to achieve your desired goals.
                </p>
              </div>
            </div>

            <div className="col-lg-3">
              <SideBar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BodySlimmingTreatment;
