import React, { useLayoutEffect } from "react";
import BlogImg1 from "../../../assets/massage-2-390x344.jpg";
import UserImg from "../../../assets/user.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faComment } from "@fortawesome/free-regular-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import SideBar from "./SideBar";

const FatLossInchLoss: React.FC = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <div className="section section-lg bg-primary container-top">
        <div className="container ">
          <div className="text-center ">
            <h1>Fat Loss & Inch Loss</h1>
          </div>
        </div>
      </div>

      <div className="container-fluid pt-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-9">
              <div className="mb-5">
                <h5>
                  Fat Loss & Inch Loss: Advanced Solutions for Body Contouring
                </h5>
                <h5>Description:</h5>
                <p>
                  Fat Loss & Inch Loss treatments are designed to target and
                  reduce localized fat deposits, helping you achieve a slimmer
                  and more contoured physique. Utilizing advanced technologies,
                  these treatments focus on both fat reduction and inch loss,
                  providing effective non-surgical solutions for body sculpting.
                </p>

                <h5>How It Works:</h5>
                <p>
                  These treatments use a variety of technologies, such as
                  cryolipolysis (fat freezing), radiofrequency, or laser
                  therapy, to break down fat cells in targeted areas. The fat
                  cells are then naturally metabolized and expelled by the body,
                  leading to a reduction in fat volume and inch loss.
                </p>

                <h5>Benefits:</h5>
                <p className="d-flex flex-column justify-content-start">
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Targeted Fat Reduction: Focuses on specific areas to reduce
                    fat and inches.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Non-Invasive: No need for surgery or extensive downtime.
                  </p>
                  <p className="rounded mb-0 d-flex align-items-center">
                    <FontAwesomeIcon icon={faAngleRight} className="me-2" />
                    Quick and Convenient: Sessions are relatively short and
                    require minimal recovery time.
                  </p>
                </p>

                <h5>The Procedure:</h5>
                <p>
                  Depending on the chosen technology, treatment sessions
                  typically last 30-60 minutes. The process involves the
                  application of the technology to the targeted areas, with
                  minimal discomfort and immediate resumption of daily
                  activities.
                </p>

                <h5>Results:</h5>
                <p>
                  Fat loss and inch reduction are typically noticeable after a
                  series of treatments. Optimal results are achieved with
                  consistent sessions, with continued improvement over time.
                </p>

                <h5>Why Choose Skin & You Clinic for Fat Loss & Inch Loss?</h5>
                <p>
                  At Skin & You Clinic, we use advanced technologies and
                  personalized treatment plans to effectively target fat
                  deposits and help you achieve your body contouring goals. Our
                  expert team ensures a comfortable and effective experience
                  tailored to your needs.
                </p>
              </div>
            </div>

            <div className="col-lg-3">
              <SideBar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FatLossInchLoss;
